.text-block {
  .prices, .preis {
    h3 {
      font-size: 22px; }
    table {
      tbody {
        tr:first-child {
          background: $primary-color;
          td {
            color: #fff; } } } }
    p {
      font-size: 13px; }
    a {
      float: right;
      background-color: $secondary-color;
      padding: 10px 20px;
      color: #fff; } } }
