header {
  width: 100%;
  height: 550px;
  background-image: url('/img/bg.png');
  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    .contain-to-grid {
      padding-top: 40px;
      position: fixed;
      z-index: 100;
      .top-bar {
        .name h1 {
          font-style: italic;
          font-weight: bolder;
          font-size: 36px;
          margin-top: 30px;
          a {
            color: #fff;
            font-family: 'Great Vibes', cursive;
            font-size: 52px;
            letter-spacing: 2px; } }
        button {
          padding: 10px 15px;
          background-color: transparent;
          height: auto;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: bolder; }

        .top-bar-section {
          width: 100%;
          position: relative;
          float: left;
          margin: 0;
          font-size: 13px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          .border {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin: 1px 0;
            .center-nav {
              display: table;
              margin: 0 auto; } }
          ul li, li:not(.has-form) a:not(.button) {
            background: none;
            margin: 0 12px; }
          li.active:not(.has-form) a:not(.button) {
            background: none;
            border-bottom: 2px solid #f00;
            margin-bottom: -2px; } } } } }
  .heading {
    margin-top: 195px;
    h2, h3 {
      color: #fff;
      text-align: center; }
    h2 {
      font-size: 42px; }
    h3 {
      font-size: 18px;
      font-style: italic; }
    .button {
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.1);
      padding: 12px 35px;
      border-radius: 5px;
      font-size: 14px;
      text-transform: uppercase;
      color: $primary-color;
      letter-spacing: 1px;
      background-color: #fff;
      color: $primary-color;
      &:hover {
        background-color: transparent;
        color: white; }
      img {
        width: 20px;
        margin-right: 10px; } } }

  .sticked {
    background-color: rgba(0,0,0,0.8); } }

@media #{$small-only} {
  .top-bar .toggle-topbar.menu-icon a span::after {
    width: 21px; }
  header {
    .overlay {
      .contain-to-grid {
        padding-top: 0;
        .top-bar {
          position: fixed;
          width: 100%;
          z-index: 50;
          background: none;
          &.expanded {
            background-color: #fff; }
          .toggle-topbar {
            width: 100%;
            position: relative;
            float: right;
            margin: 7px; }
          ul {
            li {
              margin: 0 25px;
              a {
                color: black;
                text-align: right;
                padding: 15px; } } } } } } } }
