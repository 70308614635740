html {
  padding: 0 !important; }

body.f-topbar-fixed {
  padding-top: 0 !important; }

.row {
  max-width: 1180px; }

.burger-nav {
  position: fixed;
  top: 50px;
  right: 45px;
  img {
    width: 25px;
    cursor: pointer; } }

.full-screen-nav {
  position: fixed;
  left: 0;
  top: 0;
  background: black;
  width: 100%;
  height: 100%; }
.active {
  display: table;
  width: 100%;
  height: 100%;
  ul {
    display: table-cell;
    vertical-align: middle;
    li {
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: table;
      margin: 15px auto;
      padding: 15px 0;
      &:last-child {
        border: 0; }
      a {
        font-size: 48px;
        color: white; }
      .focus {
        color: $primary-color; } } }
  .close-nav {
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 20px;
      margin-top: 63px;
      margin-right: 49px;
      cursor: pointer; } } }

.top-bar-section {
  img {
    width: 50px;
    position: absolute;
    margin-top: -5px; }
  a.logo-text {
    h2 {
      font-size: 22px;
      margin-left: 55px;
      color: #fff;
      line-height: 20px;
      .smaller {
        font-size: 11px; } } } }

.maps {
  width: 100%;
  border-top:  175px solid #fff;
  position: relative;
  float: left; }

.maps-overlay {
  height: 500px;
  z-index: 50;
  background: #222;
  padding: 150px 100px;
  h3, a {
    color: #fff; }
  h3 {
    font-size: 14px;
    &:first-child {
      font-weight: bolder;
      font-size: 18px; }
    &:last-child {
      margin-bottom: 25px; } }
  img {
    width: 35px; }
  .social {
    margin-top: 50px;
    color: #fff;
    font-weight: bolder;
    .at-share-btn {
      margin-right: 15px; }
    img {
      margin-right: 15px; } } }

footer {
  background-color: #fff;
  width: 100%;
  height: 300px;
  position: relative;
  float: left;
  img {
    width: 35px; } }

@media #{$medium-only} {
  .maps-overlay {
    padding: 150px 30px; } }

@media #{$small-only} {
  .maps-overlay {
    height: 430px;
    padding: 100px 20px; } }
