@charset "UTF-8";
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
  border-bottom: 1px solid #ddd; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.edit-header.card {
  border-bottom: 1px solid #d4d4d4;
  padding: 5px 30px; }

.cms {
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
  background-color: #f9f9f9; }
  .cms .nav-bar ul {
    margin: 0;
    padding: 0; }
  .cms .nav-bar li {
    width: 100%; }
    .cms .nav-bar li .card {
      width: 100%;
      padding: 0;
      border-bottom: 0; }
  .cms .nav-bar a {
    width: 100%;
    position: relative;
    float: left;
    padding: 10px 20px 10px 20px;
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    color: #393f5c;
    font-size: 14px;
    text-transform: capitalize;
    border-bottom: 1px solid #d4d8e4; }
    .cms .nav-bar a:hover {
      background-color: #f3f4f5; }

.side-navigation {
  width: 300px;
  position: fixed; }
  .side-navigation img {
    width: 100%; }
  .side-navigation .menu {
    margin-bottom: 45px; }
    .side-navigation .menu h2 {
      margin: 40px 0;
      font-size: 22px; }
    .side-navigation .menu ul {
      margin: 0;
      padding: 0;
      margin-left: -20px; }
      .side-navigation .menu ul li.page-item {
        width: 100%;
        position: relative;
        float: left;
        margin-bottom: 15px; }
        .side-navigation .menu ul li.page-item a {
          width: 100%;
          position: relative;
          float: left;
          padding: 5px 20px;
          color: #363636;
          text-transform: capitalize;
          font-size: 14px; }
          .side-navigation .menu ul li.page-item a:hover {
            background: #ededed;
            border-radius: 3px; }
  .side-navigation .admin-links, .side-navigation a {
    width: 100%;
    position: relative;
    float: left; }
    .side-navigation .admin-links hr, .side-navigation a hr {
      margin: 25px 0 0;
      padding: 0; }
    .side-navigation .admin-links a, .side-navigation a a {
      padding: 20px 0; }
  .side-navigation .card {
    width: 100%;
    height: 100%;
    padding: 45px; }

.slots {
  width: 75%;
  position: relative;
  float: left;
  margin-left: 320px; }
  .slots .heading {
    margin: 25px 0; }
    .slots .heading h2, .slots .heading h4 {
      font-famly: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      color: #393f5c; }
    .slots .heading h2 {
      font-size: 22px; }
    .slots .heading h4 {
      font-size: 12px;
      margin-bottom: 5px; }
  .slots .slot-list .card {
    border: 1px solid #d4d4d4;
    padding: 45px;
    border-top: 0;
    border-right: 0;
    margin: 10px 0;
    width: calc(100% - 20px);
    margin-right: 20px; }
    .slots .slot-list .card:first-child {
      border: 1px solid #d4d4d4; }
    .slots .slot-list .card.small-6 {
      width: calc(50% - 20px); }
  .slots .slot-list .title {
    margin-bottom: 15px; }
  .slots .slot-list .controls {
    width: 20%;
    display: table;
    margin: 10px auto; }
    .slots .slot-list .controls a {
      width: 100%;
      position: relative;
      float: left;
      margin: 2px 0;
      padding: 3px 10px;
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-size: 12px;
      color: #393f5c;
      text-align: center;
      background: #fff;
      border: 1px solid #d4d4d4; }

.uploadManager {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 50px;
  background: #fff; }
  .uploadManager li {
    position: relative; }
    .uploadManager li:hover .action-delete img {
      display: block; }
  .uploadManager .action-delete {
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: -15px; }
    .uploadManager .action-delete img {
      width: 15px;
      float: right;
      cursor: pointer;
      display: none; }

.category.card {
  padding: 25px 15px; }
  .category.card .row {
    margin-bottom: 50px; }

.image.card {
  padding: 25px;
  margin-bottom: 25px; }
  .image.card label, .image.card input, .image.card select {
    position: relative;
    float: left; }
  .image.card label {
    width: 100%;
    font-weight: bolder; }
  .image.card input, .image.card select, .image.card textarea {
    width: 100%; }
  .image.card textarea {
    height: 160px; }

.card.textblock {
  padding: 25px; }
  .card.textblock form {
    padding-bottom: 25px; }

.form iframe {
  display: none; }

.form .card {
  width: 100%;
  padding: 10px 5px; }

.form h2 {
  color: #393f5c;
  font-size: 22px;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  .form h2:first-letter {
    text-transform: capitalize; }

.form label {
  color: #464646;
  font-family: 'Droid Sans', sans-serif;
  font-weight: bolder;
  font-size: 11px; }

.form input[type="text"], .form input[type="password"], .form input[type="date"], .form input[type="datetime"], .form input[type="datetime-local"], .form input[type="month"], .form input[type="week"], .form input[type="email"], .form input[type="number"], .form input[type="search"], .form input[type="tel"], .form input[type="time"], .form input[type="url"], .form input[type="color"], .form textarea {
  border-color: #d6dae5;
  height: 30px; }

.form button {
  width: calc(100% - 47px);
  background: none;
  color: #707070;
  font-weight: lighter;
  font-size: 11px;
  border: 1px solid #d6dae5; }
  .form button::after {
    content: '»';
    width: 47px;
    height: 48px;
    position: absolute;
    left: 100%;
    padding-top: 16px;
    top: -1px;
    background-color: #393846;
    border: 1px solid #d6dae5;
    text-align: center;
    color: #ffffff; }

@media only screen and (min-width: 40.0625rem) {
  iframe {
    display: block; }
  .form.contact {
    margin-top: -650px; }
    .form.contact .card.label {
      color: #000000;
      margin: 15px 0; } }

@media only screen and (max-width: 40rem) {
  iframe {
    display: none; }
  .form .card.label {
    display: none; }
  .form .card.contact {
    margin-top: 0; } }

.content .category.omgeving .image {
  height: 175px; }

.content .category .image {
  height: 185px;
  background-size: cover;
  background-position: center center; }

.content .category h3 {
  color: #2f2f2f;
  font-size: 18px;
  margin: 0;
  padding: 0; }

.content .category hr {
  margin: 10px 0;
  padding: 5px; }

.content .category p {
  color: #888888;
  font-size: 13px;
  line-height: 22px; }

.content .category a.watch-now {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 25px;
  background: none;
  color: #666666;
  font-size: 12px;
  font-weight: bolder;
  text-align: right; }

.content .category.comments .comment {
  padding: 15px 0; }
  .content .category.comments .comment .date {
    font-size: 13px; }

.content .category.comments input, .content .category.comments textarea {
  background: none;
  padding: 10px;
  font-size: 12px; }

.content .category.comments input {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px; }

.content .category.comments textarea {
  height: 75px; }

.content .category.comments li {
  padding: 35px 10px; }

.content .category.comments button {
  padding: 15px 25px;
  float: right; }

@media only screen and (min-width: 64.0625rem) {
  .content .column.right {
    padding-right: 0; }
  .content .category.rooms {
    margin-right: 10px; } }

@media only screen and (max-width: 40rem) {
  .form .card {
    width: 100%; }
  h1 {
    font-size: 18px; }
  .page-title {
    display: none; } }

.text-block .prices h3, .text-block .preis h3 {
  font-size: 22px; }

.text-block .prices table tbody tr:first-child, .text-block .preis table tbody tr:first-child {
  background: #df092f; }
  .text-block .prices table tbody tr:first-child td, .text-block .preis table tbody tr:first-child td {
    color: #fff; }

.text-block .prices p, .text-block .preis p {
  font-size: 13px; }

.text-block .prices a, .text-block .preis a {
  float: right;
  background-color: #393846;
  padding: 10px 20px;
  color: #fff; }

.news h3.title, .news-item h3.title {
  color: #2f2f2f;
  font-size: 18px;
  margin: 0 0 15px 0;
  padding: 0; }
  .news h3.title.lighter, .news-item h3.title.lighter {
    font-weight: lighter;
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px; }

.content .category.news div.date {
  margin-top: 10px;
  font-size: 12px; }

.content .category.news li {
  position: relative;
  border: 20px solid #f8f8f8; }
  .content .category.news li .image {
    height: 150px; }

.content .category.news hr {
  margin-bottom: 0;
  padding-bottom: 0; }

.content .category.news a {
  position: relative; }

.news-item {
  padding: 0 15%; }
  .news-item .item .title {
    margin: 5px 0; }
  .news-item .item .img {
    width: 100%;
    height: 225px;
    position: relative;
    float: left;
    display: table;
    margin: 0 auto 25px auto;
    background-size: cover;
    background-position: center; }
  .news-item .item p {
    color: #888888;
    font-size: 13px;
    line-height: 22px; }

.placeholder .date, .placeholder h3.title, .placeholder .content-ph {
  background-color: #f8f8f8;
  height: 10px;
  margin-bottom: 15px; }

.placeholder .date {
  width: 25%;
  margin-bottom: 20px; }

.placeholder h3.title {
  height: 15px;
  margin-bottom: 2px; }

.white-ph .date, .white-ph h3.title, .white-ph .content-ph {
  background-color: #f1f1f1; }

header {
  width: 100%;
  height: 550px;
  background-image: url("/img/bg.png"); }
  header .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%; }
    header .overlay .contain-to-grid {
      padding-top: 40px;
      position: fixed;
      z-index: 100; }
      header .overlay .contain-to-grid .top-bar .name h1 {
        font-style: italic;
        font-weight: bolder;
        font-size: 36px;
        margin-top: 30px; }
        header .overlay .contain-to-grid .top-bar .name h1 a {
          color: #fff;
          font-family: 'Great Vibes', cursive;
          font-size: 52px;
          letter-spacing: 2px; }
      header .overlay .contain-to-grid .top-bar button {
        padding: 10px 15px;
        background-color: transparent;
        height: auto;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bolder; }
      header .overlay .contain-to-grid .top-bar .top-bar-section {
        width: 100%;
        position: relative;
        float: left;
        margin: 0;
        font-size: 13px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
        header .overlay .contain-to-grid .top-bar .top-bar-section .border {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          margin: 1px 0; }
          header .overlay .contain-to-grid .top-bar .top-bar-section .border .center-nav {
            display: table;
            margin: 0 auto; }
        header .overlay .contain-to-grid .top-bar .top-bar-section ul li, header .overlay .contain-to-grid .top-bar .top-bar-section li:not(.has-form) a:not(.button) {
          background: none;
          margin: 0 12px; }
        header .overlay .contain-to-grid .top-bar .top-bar-section li.active:not(.has-form) a:not(.button) {
          background: none;
          border-bottom: 2px solid #f00;
          margin-bottom: -2px; }
  header .heading {
    margin-top: 195px; }
    header .heading h2, header .heading h3 {
      color: #fff;
      text-align: center; }
    header .heading h2 {
      font-size: 42px; }
    header .heading h3 {
      font-size: 18px;
      font-style: italic; }
    header .heading .button {
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.1);
      padding: 12px 35px;
      border-radius: 5px;
      font-size: 14px;
      text-transform: uppercase;
      color: #df092f;
      letter-spacing: 1px;
      background-color: #fff;
      color: #df092f; }
      header .heading .button:hover {
        background-color: transparent;
        color: white; }
      header .heading .button img {
        width: 20px;
        margin-right: 10px; }
  header .sticked {
    background-color: rgba(0, 0, 0, 0.8); }

@media only screen and (max-width: 40rem) {
  .top-bar .toggle-topbar.menu-icon a span::after {
    width: 21px; }
  header .overlay .contain-to-grid {
    padding-top: 0; }
    header .overlay .contain-to-grid .top-bar {
      position: fixed;
      width: 100%;
      z-index: 50;
      background: none; }
      header .overlay .contain-to-grid .top-bar.expanded {
        background-color: #fff; }
      header .overlay .contain-to-grid .top-bar .toggle-topbar {
        width: 100%;
        position: relative;
        float: right;
        margin: 7px; }
      header .overlay .contain-to-grid .top-bar ul li {
        margin: 0 25px; }
        header .overlay .contain-to-grid .top-bar ul li a {
          color: black;
          text-align: right;
          padding: 15px; } }

.bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.padding-top {
  padding-top: 25px; }

.padding-bottom {
  padding-top: 50px; }

.card {
  background: #fff;
  padding: 5px;
  border: 1px solid #d4d4d4;
  width: 100%;
  position: relative;
  float: left; }
  .card .text {
    padding: 15px; }
  .card .image {
    width: 100%; }

.table {
  height: 100%;
  display: table;
  margin: 0 auto; }
  .table .table-cell {
    display: table-cell;
    vertical-align: middle; }
    .table .table-cell .button {
      display: table;
      margin: 25px auto; }

@media only screen and (min-width: 40.0625rem) {
  .nopadding-right {
    padding-right: 0 !important; }
  .nopadding-left {
    padding-left: 0 !important; } }

@media only screen and (max-width: 40rem) {
  .img-bg .red-block {
    padding: 20px 40px; } }

html {
  padding: 0 !important; }

body.f-topbar-fixed {
  padding-top: 0 !important; }

.row {
  max-width: 1180px; }

.burger-nav {
  position: fixed;
  top: 50px;
  right: 45px; }
  .burger-nav img {
    width: 25px;
    cursor: pointer; }

.full-screen-nav {
  position: fixed;
  left: 0;
  top: 0;
  background: black;
  width: 100%;
  height: 100%; }

.active {
  display: table;
  width: 100%;
  height: 100%; }
  .active ul {
    display: table-cell;
    vertical-align: middle; }
    .active ul li {
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: table;
      margin: 15px auto;
      padding: 15px 0; }
      .active ul li:last-child {
        border: 0; }
      .active ul li a {
        font-size: 48px;
        color: white; }
      .active ul li .focus {
        color: #df092f; }
  .active .close-nav {
    position: absolute;
    top: 0;
    right: 0; }
    .active .close-nav img {
      width: 20px;
      margin-top: 63px;
      margin-right: 49px;
      cursor: pointer; }

.top-bar-section img {
  width: 50px;
  position: absolute;
  margin-top: -5px; }

.top-bar-section a.logo-text h2 {
  font-size: 22px;
  margin-left: 55px;
  color: #fff;
  line-height: 20px; }
  .top-bar-section a.logo-text h2 .smaller {
    font-size: 11px; }

.maps {
  width: 100%;
  border-top: 175px solid #fff;
  position: relative;
  float: left; }

.maps-overlay {
  height: 500px;
  z-index: 50;
  background: #222;
  padding: 150px 100px; }
  .maps-overlay h3, .maps-overlay a {
    color: #fff; }
  .maps-overlay h3 {
    font-size: 14px; }
    .maps-overlay h3:first-child {
      font-weight: bolder;
      font-size: 18px; }
    .maps-overlay h3:last-child {
      margin-bottom: 25px; }
  .maps-overlay img {
    width: 35px; }
  .maps-overlay .social {
    margin-top: 50px;
    color: #fff;
    font-weight: bolder; }
    .maps-overlay .social .at-share-btn {
      margin-right: 15px; }
    .maps-overlay .social img {
      margin-right: 15px; }

footer {
  background-color: #fff;
  width: 100%;
  height: 300px;
  position: relative;
  float: left; }
  footer img {
    width: 35px; }

@media only screen and (min-width: 40.0625rem) and (max-width: 64rem) {
  .maps-overlay {
    padding: 150px 30px; } }

@media only screen and (max-width: 40rem) {
  .maps-overlay {
    height: 430px;
    padding: 100px 20px; } }

.intro-row {
  position: relative;
  float: left;
  padding: 110px 0 200px 0; }
  .intro-row h3, .intro-row h2 {
    font-weight: bolder; }
  .intro-row h3 {
    color: #df092f;
    font-size: 22px;
    margin-bottom: -5px; }
  .intro-row h2 {
    color: #383745;
    margin-bottom: 15px; }
  .intro-row p {
    font-size: 15px;
    opacity: 0.8;
    line-height: 26px; }
  .intro-row .intro-image {
    height: 250px; }
  .intro-row .intro {
    height: 100%;
    margin: 35px 0;
    padding-left: 35px; }
    .intro-row .intro p {
      margin: 0;
      padding: 0; }

.img-bg {
  height: 860px;
  background-image: url("/img/products.jpg");
  background-size: cover;
  position: relative; }
  .img-bg .row {
    position: relative; }
  .img-bg .overlay {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px 0; }
  .img-bg .red-row {
    margin-top: -105px;
    margin-bottom: 20px; }
  .img-bg .red-block, .img-bg .blue-gray-block {
    height: 500px;
    position: relative;
    padding: 50px 100px;
    z-index: 49; }
    .img-bg .red-block .button a, .img-bg .blue-gray-block .button a {
      color: white; }
    .img-bg .red-block img, .img-bg .blue-gray-block img {
      width: 55px; }
    .img-bg .red-block h2, .img-bg .red-block p, .img-bg .blue-gray-block h2, .img-bg .blue-gray-block p {
      color: #fff; }
    .img-bg .red-block h2, .img-bg .blue-gray-block h2 {
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 38px;
      margin-bottom: 15px; }
    .img-bg .red-block p, .img-bg .blue-gray-block p {
      font-size: 16px;
      line-height: 24px; }
    .img-bg .red-block .button, .img-bg .blue-gray-block .button {
      border-radius: 7px;
      border: 2px solid #fff;
      border-bottom: 4px solid #fff;
      padding: 12px 22px; }
  .img-bg .red-block {
    background-color: #df092f; }
    .img-bg .red-block a .button {
      background: none; }
    .img-bg .red-block a:hover .button {
      background-color: white;
      color: #df092f; }
  .img-bg .blue-gray-block {
    background-color: #393846;
    float: right; }
    .img-bg .blue-gray-block a .button {
      background: none; }
    .img-bg .blue-gray-block a:hover .button {
      background-color: white;
      color: #393846; }
  .img-bg .picture-one, .img-bg .picture-two {
    height: 500px; }

@media only screen and (max-width: 40rem) {
  .intro-row {
    padding: 75px 0; }
  .img-bg {
    margin-top: 75px; }
    .img-bg .red-row .image {
      padding: 0; }
    .img-bg .red-block, .img-bg .blue-gray-block {
      height: 550px;
      padding: 30px; }
      .img-bg .red-block h2, .img-bg .blue-gray-block h2 {
        font-size: 24px; }
    .img-bg .picture-one, .img-bg .picture-two {
      height: 220px; } }
