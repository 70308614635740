.side-navigation {
  width: 300px;
  position: fixed;
  img {
    width: 100%; }
  .menu {
    margin-bottom: 45px;
    h2 {
      margin: 40px 0;
      font-size: 22px; }
    ul {
      margin: 0;
      padding: 0;
      margin-left: -20px;
      li.page-item {
        width: 100%;
        position: relative;
        float: left;
        margin-bottom: 15px;
        a {
          width: 100%;
          position: relative;
          float: left;
          padding: 5px 20px;
          color: #363636;
          text-transform: capitalize;
          font-size: 14px;
          &:hover {
            background: #ededed;
            border-radius: 3px; } } } } }
  .admin-links, a {
    width: 100%;
    position: relative;
    float: left;
    hr {
      margin: 25px 0 0;
      padding: 0; }
    a {
      padding: 20px 0; } }
  .card {
    width: 100%;
    height: 100%;
    padding: 45px; } }
