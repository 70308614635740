.content {
  .category {
    &.omgeving {
      .image {
        height: 175px; } }
    .image {
      height: 185px;
      background-size: cover;
      background-position: center center; }
    h3 {
      color: #2f2f2f;
      font-size: 18px;
      margin: 0;
      padding: 0; }
    hr {
      margin: 10px 0;
      padding: 5px; }
    p {
      color: #888888;
      font-size: 13px;
      line-height: 22px; }
    a.watch-now {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      padding: 25px;
      background: none;
      color: #666666;
      font-size: 12px;
      font-weight: bolder;
      text-align: right; }
    &.comments {
      .comment {
        padding: 15px 0;
        .date {
          font-size: 13px; } }
      input, textarea {
        background: none;
        padding: 10px;
        font-size: 12px; }
      input {
        box-shadow: none;
        border: 0;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px; }
      textarea {
        height: 75px; }
      li {
        padding: 35px 10px; }
      button {
        padding: 15px 25px;
        float: right; } } } }




@media #{$large-up} {
  .content {
    .column {
      &.right {
        padding-right: 0; } }
    .category {
      &.rooms {
        margin-right: 10px; } } } }


@media #{$small-only} {
  .form .card {
    width: 100%; }
  h1 {
     font-size: 18px; }
  .page-title {
    display: none; } }

