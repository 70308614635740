.slots {
  width: 75%;
  position: relative;
  float: left;
  margin-left: 320px;
  .heading {
    margin: 25px 0;
    h2, h4 {
      font-famly: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      color: #393f5c; }
    h2 {
      font-size: 22px; }
    h4 {
      font-size: 12px;
      margin-bottom: 5px; } }
  .slot-list {
    .card {
      border: 1px solid #d4d4d4;
      padding: 45px;
      border-top: 0;
      border-right: 0;
      margin: 10px 0;
      width: calc(100% - 20px);
      margin-right: 20px;
      &:first-child {
        border: 1px solid #d4d4d4; }
      &.small-6 {
        width: calc(50% - 20px); } }

    .title {
      margin-bottom: 15px; }
    .controls {
      width: 20%;
      display: table;
      margin: 10px auto;
      a {
        width: 100%;
        position: relative;
        float: left;
        margin: 2px 0;
        padding: 3px 10px;
        font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-size: 12px;
        color: #393f5c;
        text-align: center;
        background: #fff;
        border: 1px solid #d4d4d4; } } } }
