.news, .news-item {
  h3.title {
    color: #2f2f2f;
    font-size: 18px;
    margin: 0 0 15px 0;
    padding: 0;
    &.lighter {
      font-weight: lighter;
      text-align: center;
      margin-bottom: 15px;
      font-size: 16px; } } }

.content {
  .category {
    &.news {
      div.date {
        margin-top: 10px;
        font-size: 12px; }
      li {
        position: relative;
        border: 20px solid #f8f8f8;
        .image {
          height: 150px; } }
      hr {
        margin-bottom: 0;
        padding-bottom: 0; }
      a {
        position: relative; } } } }

.news-item {
  padding: 0 15%;
  .item {
    .title {
      margin: 5px 0; }
    .img {
      width: 100%;
      height: 225px;
      position: relative;
      float: left;
      display: table;
      margin: 0 auto 25px auto;
      background-size: cover;
      background-position: center; }
    p {
      color: #888888;
      font-size: 13px;
      line-height: 22px; } } }


.placeholder {
  .date, h3.title, .content-ph {
    background-color: #f8f8f8;
    height: 10px;
    margin-bottom: 15px; }
  .date {
    width: 25%;
    margin-bottom: 20px; }
  h3.title {
    height: 15px;
    margin-bottom: 2px; } }

.white-ph {
  .date, h3.title, .content-ph {
    background-color: #f1f1f1; } }
