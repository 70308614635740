input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
  border-bottom: 1px solid #ddd; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }


.edit-header {
  &.card {
    border-bottom: 1px solid #d4d4d4;
    padding: 5px 30px; } }

.cms {
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
  background-color: #f9f9f9;
  .nav-bar {
    ul {
      margin: 0;
      padding: 0; }
    li {
      width: 100%;
      .card {
        width: 100%;
        padding: 0;
        border-bottom: 0; } }
    a {
      width: 100%;
      position: relative;
      float: left;
      padding: 10px 20px 10px 20px;
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      color: #393f5c;
      font-size: 14px;
      text-transform: capitalize;
      border-bottom: 1px solid #d4d8e4;
      &:hover {
        background-color: #f3f4f5; } } } }

