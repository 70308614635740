.uploadManager {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 50px;
  background: #fff;
  li {
    position: relative;
    &:hover {
      .action-delete {
        img {
          display: block; } } } }
  .action-delete {
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: -15px;
    img {
      width: 15px;
      float: right;
      cursor: pointer;
      display: none; } } }
