.image {
  &.card {
    padding: 25px;
    margin-bottom: 25px;
    label, input, select {
      position: relative;
      float: left; }
    label {
      width: 100%;
      font-weight: bolder; }
    input, select, textarea {
      width: 100%; }
    textarea {
      height: 160px; } } }
