.intro-row {
  position: relative;
  float: left;
  padding: 110px 0 200px 0;
  h3, h2 {
    font-weight: bolder; }
  h3 {
    color: $primary-color;
    font-size: 22px;
    margin-bottom: -5px; }
  h2 {
    color: #383745;
    margin-bottom: 15px; }
  p {
    font-size: 15px;
    opacity: 0.8;
    line-height: 26px; }
  .intro-image {
    height: 250px; }
  .intro {
    height: 100%;
    margin: 35px 0;
    padding-left: 35px;
    p {
      margin: 0;
      padding: 0; } } }

.img-bg {
  height: 860px;
  background-image: url('/img/products.jpg');
  background-size: cover;
  position: relative;
  .row {
    position: relative; }
  .overlay {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px 0; }
  .red-row {
    margin-top: -105px;
    margin-bottom: 20px; }
  .red-block, .blue-gray-block {
    height: 500px;
    position: relative;
    padding: 50px 100px;
    z-index: 49;
    .button a {
      color: white; }
    img {
      width: 55px; }
    h2, p {
      color: #fff; }
    h2 {
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 38px;
      margin-bottom: 15px; }
    p {
      font-size: 16px;
      line-height: 24px; }
    .button {
      border-radius: 7px;
      border: 2px solid #fff;
      border-bottom: 4px solid #fff;
      padding: 12px 22px; } }
  .red-block {
    background-color: $primary-color;
    a {
      .button {
        background: none; }
      &:hover {
        .button {
          background-color: white;
          color: $primary-color; } } } }
  .blue-gray-block {
    background-color: $secondary-color;
    float: right;
    a {
      .button {
        background: none; }
      &:hover {
        .button {
          background-color: white;
          color: $secondary-color; } } } }
  .picture-one,  .picture-two {
    height: 500px; } }

@media #{$small-only} {
  .intro-row {
    padding: 75px 0; }
  .img-bg {
    margin-top: 75px;
    .red-row {
      .image {
        padding: 0; } }
    .red-block, .blue-gray-block {
      height: 550px;
      padding: 30px;
      h2 {
        font-size: 24px; } }
    .picture-one, .picture-two {
      height: 220px; } } }
