$footer-height: 360px;

$primary-color: #df092f;
$secondary-color: #393846;

$white: #ffffff;
$black: #000000;

$primary-font: 'Open Sans', sans-serif;
$title-color: #2f2f2f;
