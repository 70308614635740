.bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.padding-top {
  padding-top: 25px; }

.padding-bottom {
  padding-top: 50px; }

.card {
  background: #fff;
  padding: 5px;
  border: 1px solid #d4d4d4;
  width: 100%;
  position: relative;
  float: left;
  .text {
    padding: 15px; }
  .image {
    width: 100%; } }

.table {
  height: 100%;
  display: table;
  margin: 0 auto;
  .table-cell {
    display: table-cell;
    vertical-align: middle;
    .button {
      display: table;
      margin: 25px auto; } } }

@media #{$medium-up} {
  .nopadding-right {
    padding-right: 0 !important; }

  .nopadding-left {
    padding-left: 0 !important; } }

@media #{$small-only} {
  .img-bg {
    .red-block {
      padding: 20px 40px; } } }
